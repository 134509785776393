import Product from "../interfaces/Product.interface";
import { CourseType } from "../types/Course.type";
import { PackType } from "../types/Pack.type";

export default class Discount {
    public id: number;
    public title: string;
    public percentage: number;
    public value: number;
    public startDate: Date | null;
    public endDate: Date | null;
    public quantity: number;
    public discount?: string; //the discount name when applied to a pack
    public courses: CourseType[];
    public packs: PackType[];
    public visible: boolean = true;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.percentage = data.percentage;
        this.value = data.value;
        this.startDate = data.start_date ? new Date(data.start_date) : null;
        this.endDate = data.end_date ? new Date(data.end_date) : null;
        this.quantity = data.quantity;
        this.courses = data.courses;
        this.packs = data.packs;
        this.discount = data.discount;
        this.visible = data.visible;
    }

    clone(): Discount {
        const clonedDisc = new Discount({});
        clonedDisc.id = this.id
        clonedDisc.title = this.title;
        clonedDisc.percentage = this.percentage;
        clonedDisc.value = this.value;
        clonedDisc.startDate = this.startDate;
        clonedDisc.endDate = this.endDate;
        clonedDisc.quantity = this.quantity;
        clonedDisc.visible = this.visible;
        return clonedDisc;
    }

    getDiscountValueForProduct(product: Product) {
        return this.percentage ?
            (product.getPrice() * this.percentage).toFixed(2) :
            (product.getPrice() - this.value).toFixed(2);
    }
}