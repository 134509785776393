import { api } from '../../services/api';
import React, { useEffect, useState, useContext } from "react";
import { MessagingContext } from '../App';
import Loader from '../shared/Loader';
import Discount from '../../models/Discount.model';
import DiscountsFormModal from '../discounts/DiscountsFormModal';
import { formatCurrency } from '../../utils/generic';
import Product from '../../interfaces/Product.interface';

type DiscountComboProps = {
    discount: Discount | undefined;
    product: Product;
    selectionChanged: (newVal: Discount | undefined) => void;
    discountAddedList: (newDiscount: Discount) => void;
    discountChanged: (newDiscount: Discount) => void;
    showError?: boolean;
    defaultText?: string;
    availableDiscounts?: Discount[] | undefined;
    autoFetchDiscounts?: boolean;
}

export default function DiscountCombo(props: DiscountComboProps) {
    const messaging = useContext(MessagingContext);
    const [discounts, setDiscounts] = useState<Discount[] | undefined>();
    const [showInsertModal, setShowInsertModal] = useState(false);

    const [selectedDiscount, setSelectedDiscount] = useState<Discount | undefined>(undefined);

    const showDiscounts = props.autoFetchDiscounts !== false ? discounts : props.availableDiscounts;

    useEffect(() => {
        if (props.autoFetchDiscounts !== false) {
            api.getCourses(
                (data: any) => setDiscounts(data.map((c: any) => new Discount(c))),
                err => messaging.modal.showMessage("Erro", "Erro a obter os cursos: " + err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleDiscountChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectIntVal = parseInt(event.target.value);
        if(selectIntVal == 0){
            setSelectedDiscount(undefined);
            props.selectionChanged(undefined);
        }
        if (showDiscounts) {
            const newSelectedDiscount = showDiscounts.find(discount => discount.id === selectIntVal);
            if (newSelectedDiscount) {
                props.selectionChanged(newSelectedDiscount);
            }
        }
    }

    return (
        <>
            {showDiscounts ?
                <>
                    {showInsertModal &&
                        <DiscountsFormModal
                            closeHandler={() => setShowInsertModal(false)}
                            discount={selectedDiscount}
                            addDiscount={props.discountAddedList}
                            editDiscount={props.discountChanged}
                        />}
                    <div className="d-flex" style={{ alignItems: 'baseline' }}>
                        <button type="button"
                            style={{ fontSize: "1rem", marginRight: "5px" }}
                            onClick={() => {
                                setShowInsertModal(true);
                                setSelectedDiscount(undefined);
                            }}
                            className={`btn btn-outline-success btn-rounded waves-effect`}>
                            <i className="fas fa-plus-square"></i>
                        </button>
                        {props.discount && <button type="button"
                            style={{ fontSize: "1rem", marginRight: "5px" }}
                            onClick={() => {
                                setShowInsertModal(true);
                                setSelectedDiscount(props.discount);
                            }}
                            className={`btn btn-outline-success btn-rounded waves-effect`}>
                            <i className="fas fa-pen-square"></i>
                        </button>}
                        <div>
                            <select
                                value={typeof props.discount === "undefined" ? 0 : props.discount.id}
                                className="form-select"
                                onChange={handleDiscountChange}>
                                <option value={0} selected>{props.defaultText || "Escolha o desconto"}</option>
                                {
                                    showDiscounts
                                        .map((course: Discount, pos: number) =>
                                            <option
                                                key={`${course.id}-${pos}`}
                                                value={course.id}>{course.title}</option>
                                        )}
                            </select>
                        </div>
                        {formatCurrency(props.product.getDiscountValue(props.discount))}
                    </div>
                </>
                :
                <Loader />
            }
        </>
    );
}